import {Button, StatusTag, Text} from '@prescriberpoint/ui';
import {FC, useId, useMemo} from 'react';
import ProgramCosts from '../AffordabilityProgramCard/ProgramCosts';
import Icon from '../Icon';

export interface IAffordabilityHighlightedProgramProps {
  id: string;
  title: string;
  assistanceType: string;
  copayCost?: number | null;
  annualCapCost?: string | null;
  onClick?: () => void;
}

const AffordabilityHighlightedProgram: FC<
  IAffordabilityHighlightedProgramProps
> = ({id, title, assistanceType, copayCost, annualCapCost, onClick}) => {
  const generatedId = useId();
  const programTypeText = useMemo(
    () => (assistanceType === 'Coupon' ? 'Manufacturer Copay' : assistanceType),
    [assistanceType],
  );
  return (
    <div
      id={id ?? generatedId}
      data-testid={id ?? 'affordability-highlighted-program-card'}
      className='flex flex-col justify-between gap-y-5 rounded-xl bg-theme-lighter-alt p-6 md:flex-row'>
      <div className='flex flex-col gap-y-3 md:gap-y-2'>
        <div className='flex md:hidden'>
          <StatusTag
            className='whitespace-nowrap'
            label={programTypeText}
            type='outline'
            size='lg'
          />
        </div>
        <div className='flex flex-col gap-y-1'>
          <Text as='headline-sm' weight='bold'>
            {title}
          </Text>
          <ProgramCosts copayCost={copayCost} annualCapCost={annualCapCost} />
        </div>
        <StatusTag
          className='hidden whitespace-nowrap md:flex'
          label={programTypeText}
          type='outline'
          size='lg'
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-y-5 text-center md:gap-y-3'>
        <Button
          id='highlight_program_get_info'
          size='lg'
          variant='secondary'
          showIconRight
          iconRightName={<Icon name='arrow-right' size={16} />}
          className='w-full md:w-62'
          onClick={onClick}>
          Get Info
        </Button>
        <Text as='body-xs'>Subject to eligibility requirements </Text>
      </div>
    </div>
  );
};

export default AffordabilityHighlightedProgram;
