import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo} from 'react';
import HeroIcons from '../HeroIcons';
import BoxedWarning from './BoxedWarning';
import CTAMenuV3 from './CTAMenuV3';
import Dosages from './Dosages';
import Otc from './OTC';
import PrescriberAIMobile from './PrescriberAIMobile';
import RemsInfo from './RemsInfo';
import {HEADER_REMS_INFO} from '@/constants/flags';
import {useMobileContentContext, useUserAgentContext, useFlag} from '@/context';
import {useIsOtc} from '@/hooks';

export interface PdpHeaderV1Props {
  drugName: string;
  genericName: string;
}

const PdpHeaderV1: FC<PdpHeaderV1Props> = ({drugName, genericName}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {showCTAMenu} = useMobileContentContext();
  const isOtc = useIsOtc();

  const showRemsInfo = useFlag(HEADER_REMS_INFO);

  const renderGenericName = useMemo(
    () => drugName.toLocaleLowerCase() !== genericName.toLocaleLowerCase(),
    [drugName, genericName],
  );
  return (
    <>
      <div id='pdp-header-v1'>
        <div className='mb-4 mt-5 flex items-center justify-between sm:flex-row md:mb-3 xs-only:flex-col'>
          <div className='flex flex-wrap items-center justify-start xs-only:w-full'>
            <Text
              as={isMobileOrTablet ? 'headline-md' : 'headline-xl'}
              casing='title'
              className='md:mr-4 md:w-auto xs-only:mr-1 xs-only:w-auto sm-only:w-full sm-only:!text-[30px]'>
              {drugName}
            </Text>
            {renderGenericName ? (
              <Text
                as='body-md'
                weight='bold'
                className='mr-1 text-neutral-secondary-alt'>
                ({genericName})
              </Text>
            ) : null}
            <div className='xs-only:mt-3 xs-only:w-full'>
              <Dosages />
            </div>
          </div>
          <div className='flex items-center space-x-1 sm:justify-end md:w-[40%] xs-only:mt-3 xs-only:w-full xs-only:justify-between xs-only:overflow-x-auto'>
            <div className='flex items-center gap-x-2 xs-only:flex-row-reverse'>
              {showRemsInfo && <RemsInfo />}
              {isOtc ? <Otc /> : null}
              <BoxedWarning />
            </div>
            <HeroIcons drugName={drugName} />
          </div>
        </div>
        <div className={clsx('mt-6 xs-only:mb-4', {invisible: !showCTAMenu})}>
          <CTAMenuV3 />
        </div>
      </div>
      {isMobileOrTablet ? <PrescriberAIMobile /> : null}
    </>
  );
};

export default PdpHeaderV1;
