import {capitalizeFirstLetter, joinWords} from '.';
import {DrugName, LabelListItem, LabelListResult, ProductDto} from '@/models';

export function getDosages(product: ProductDto, drugName: string) {
  if (!product?.dosages) {
    return '';
  }
  const dosages = product.dosages?.map(
    (dosage) => `${dosage.dose} ${dosage.form}`,
  );
  return `${drugName} is available in ${
    product?.dosages?.length
  } dosages, including ${joinWords(dosages, 'and')}`;
}

export function getHowAdministrated(product: ProductDto, drugName: string) {
  const administrationOptions = new Set<string>();
  product?.dosages?.forEach((dosage) =>
    administrationOptions.add(dosage.route),
  );
  return `${drugName} is administered as a ${joinWords(
    Array.from(administrationOptions),
  )}`;
}

function getIndications(product: ProductDto, drugName: string) {
  if (!product?.indications) {
    return '';
  }
  const indications = product?.indications?.map(
    (indication) => indication.name,
  );
  return `${drugName} treats ${joinWords(indications, 'and')}`;
}

function getMadeOf(product: ProductDto, drugName: string) {
  if (!product?.classes || !product?.names) {
    return '';
  }
  const productClassName = product?.classes?.find(
    (classData) => classData.type === 'EPC',
  )?.name;
  if (!productClassName) return '';

  const filterTTY = (tty: string) =>
    product.names?.filter((nameData) => nameData.tty === tty);
  let filteredNames = filterTTY('MIN');
  if (filteredNames?.length === 0) {
    filteredNames = filteredNames.concat(filterTTY('IN'));
    if (filteredNames?.length === 0) {
      const classes = product.classes.filter(
        (classData) => classData.type === 'EPC',
      );
      const names = classes.map((classData) => classData.name);
      return `${drugName} contains ${joinWords(names)}`;
    }
  }
  const madeOfNames = filteredNames.map((nameData) => nameData.name);

  return `${drugName} contains ${joinWords(madeOfNames, 'and')} which is a ${
    product?.classes?.find((classData) => classData.type === 'EPC')?.name
  }`;
}

function getMOA(product: ProductDto, drugName: string) {
  if (!product?.classes) {
    return '';
  }
  const moa = product.classes
    ?.filter((classData) => classData.type === 'MOA')
    .map((classData) => classData.name);
  if (moa?.length === 0) return '';
  return `${drugName} mechanism of action is ${joinWords(moa)}`;
}

export const getProductName = (product: ProductDto | null) =>
  product?.names?.find((name) => name.tty === 'BN')?.name ?? '';

export function getProductFAQData(product: ProductDto | null | undefined) {
  if (product === null || product === undefined)
    return {
      dosages: '',
      howAdministrated: '',
      indications: '',
      madeOf: '',
      moa: '',
      name: '',
    };

  const name = getProductName(product);
  const dosages = getDosages(product, name);
  const howAdministrated = getHowAdministrated(product, name);
  const indications = getIndications(product, name);
  const madeOf = getMadeOf(product, name);
  const moa = getMOA(product, name);

  return {dosages, howAdministrated, indications, madeOf, moa, name};
}

export function findNameFromLabel(tty: string, names?: DrugName[]) {
  switch (tty) {
    case 'BN':
    case 'PIN':
      return names?.find((name) => name.tty === tty)?.name ?? '';
    case 'IN':
      return (
        names?.find((name) => name.tty === 'MIN')?.name ??
        names
          ?.filter((name) => name.tty === tty)
          .map((name) => name.name)
          ?.join(', ') ??
        ''
      );
    default:
      return undefined;
  }
}

export function getLabelTitle(names?: DrugName[], labelTitle?: string) {
  let result = '';
  const bnName = findNameFromLabel('BN', names);
  if (bnName) {
    result = bnName;
  } else if (labelTitle) {
    result = labelTitle;
  } else {
    result = findNameFromLabel('IN', names) ?? '';
  }

  return capitalizeFirstLetter(result);
}

export function getListingData(product: LabelListResult): LabelListItem {
  const labelTitle = getLabelTitle(product.names, product.labelTitle);
  const indications = product.indications.map((indication) => indication.name);
  const classes = product.classes.map((classData) => classData.name);
  const generic = !!product.metadata?.generic;
  const names: string[] = [];
  const ingredients: Set<string> = new Set();

  product.names?.forEach(({name, tty}) => {
    switch (tty) {
      case 'PIN':
      case 'IN':
        ingredients.add(name);
        names.push(name);
        break;
      case 'BN':
        names.push(name);
        break;
    }
  });

  return {
    slug: product.slugId,
    setId: product.setId,
    labelTitle,
    indications,
    classes,
    generic,
    names,
    ingredients: Array.from(ingredients),
  };
}
