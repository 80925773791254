import {MarketingFooter} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {useToggle} from 'usehooks-ts';
import CookieConsent from './CookieConsent';
import {SHOW_COOKIE_POLICY_IN_FOOTER} from '@/constants/flags';
import {useFlag} from '@/context';
import {useUser} from '@/hooks';

interface FooterProps {
  showSitemapLinks?: boolean;
  showCookiePolicyInFooter?: boolean;
}

const Footer: FC<FooterProps> = () => {
  const [isModalActive, toggleModal] = useToggle();
  const policyInFooter = useFlag(SHOW_COOKIE_POLICY_IN_FOOTER);
  const router = useRouter();
  const {logged} = useUser();

  const shouldHideSitemapLinks = logged || router.pathname === '/home';

  return (
    <>
      <MarketingFooter
        showSitemapLinks={!shouldHideSitemapLinks}
        showCookiePolicyInFooter={policyInFooter}
        onCookiePolicyClick={() => toggleModal()}
        className='w-full'
      />
      <CookieConsent isModalActive={isModalActive} toggleModal={toggleModal} />
    </>
  );
};

export default Footer;
