import {useCallback, useMemo} from 'react';
import {useEnhancedContent} from '@/queries';
import {useLabel} from '@/queries/useLabel';
import {findNameFromLabel, getLabelTitle} from '@/utils/product';

export function useDrugNameBySetId(setId: string) {
  const {data: product, isLoading} = useLabel(setId);
  const {data: enhancedData, isLoading: isLoadingEnhanced} =
    useEnhancedContent(setId);

  const getNames = useCallback(() => {
    const names =
      product?.names
        ?.filter((name) => ['IN', 'PIN', 'BN', 'MIN'].includes(name.tty))
        .map((name) => name.name) ?? [];
    const brandName = enhancedData?.enhancedContent?.brandName ?? '';
    names?.push(brandName);
    const uniqueNames = [...new Set(names)];
    return uniqueNames.filter((name: string) => name).join(',');
  }, [enhancedData?.enhancedContent?.brandName, product?.names]);

  return useMemo(() => {
    const drugName =
      enhancedData?.enhancedContent?.brandName ||
      getLabelTitle(product?.names, product?.label?.title);

    const pinName = findNameFromLabel('PIN', product?.names) ?? '';
    const genericName =
      pinName !== ''
        ? pinName
        : (findNameFromLabel('IN', product?.names) ?? '');

    const drugClassName =
      product?.classes?.find(
        (drugClass) => drugClass.type.toLowerCase() === 'epc',
      )?.name ?? '';
    const labelerName = product?.label?.labelerName ?? '';
    const names = getNames();

    return {
      drugName,
      genericName,
      drugClassName,
      labelerName,
      names,
      isLoading: isLoading || isLoadingEnhanced,
      setId,
    };
  }, [
    enhancedData?.enhancedContent?.brandName,
    product?.label?.title,
    product?.label?.labelerName,
    product?.classes,
    product?.names,
    isLoading,
    isLoadingEnhanced,
    getNames,
    setId,
  ]);
}
