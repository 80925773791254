import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import Icon from '../Icon';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import {customEvent} from '@/utils/gtm';

const MedAIMobileCta: FC = () => {
  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'med_ai_wc_button'});
  };

  return (
    <div className='static animate-fadeIn'>
      <div className='fixed bottom-0 left-0 z-20 w-full'>
        <button
          id='med_ai_wc_button'
          onClick={onHandleClick}
          className='flex h-14 w-full cursor-pointer items-center gap-x-2 rounded-t-[20px] border border-solid border-theme-lighter bg-green-100 px-5 py-4 text-left text-neutral-darker'>
          <Icon name='bot-message-square' size={32} />
          <Text
            as='body-md'
            weight='bold'
            className='flex-1 !text-neutral-darker'>
            Ask me anything
          </Text>
          <Icon name='chevron-up' size={24} />
        </button>
      </div>
    </div>
  );
};

export default MedAIMobileCta;
